import React, { useRef, useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CallToAction from '../components/CallToAction'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import MapOP from "../components/MapOroNew";
import { Wrapper } from "@googlemaps/react-wrapper";
import homeVideo from '../assets/home/video-home.webm';
import homeVideo1 from '../assets/home/video-home.mp4';
import { useInView } from 'react-intersection-observer';
import Logo from '../assets/logo/logo2.png';
import pietro from "../assets/team/pietronuovo.webp";
import iris from "../assets/team/irisnuovo.webp";
import walter from "../assets/team/walternuovo.webp";
import teamImg from "../assets/team/team-1.webp"
import VisibilitySensor from 'react-visibility-sensor';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { BsFacebook, BsInstagram, BsTelephoneFill, BsWhatsapp } from 'react-icons/bs'; 
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import img1 from "../assets/slidehome/1.webp";
import img2 from "../assets/slidehome/2.webp";
import img3 from "../assets/slidehome/3.webp";
import img4 from "../assets/slidehome/4.webp";
import img5 from "../assets/slidehome/5.webp";
import img6 from "../assets/slidehome/6.webp";
import img7 from "../assets/slidehome/7.webp";
import img8 from "../assets/slidehome/8.webp";
import img9 from "../assets/slidehome/9.webp";
import img10 from "../assets/slidehome/10.webp";
import img11 from "../assets/slidehome/11.webp";
import img12 from "../assets/slidehome/12.webp";
import img13 from "../assets/slidehome/13.webp";
import img14 from "../assets/slidehome/14.webp";
import img15 from "../assets/slidehome/15.webp";
import img16 from "../assets/slidehome/16.webp";
import img17 from "../assets/slidehome/17.webp";
import img18 from "../assets/slidehome/18.webp";
import img19 from "../assets/slidehome/19.webp";
import img20 from "../assets/slidehome/20.webp";
import img21 from "../assets/slidehome/21.webp";
import img22 from "../assets/slidehome/22.webp";
import img23 from "../assets/slidehome/23.webp";
import img24 from "../assets/slidehome/24.webp";
import img25 from "../assets/slidehome/25.webp";
import img26 from "../assets/slidehome/26.webp";
import img27 from "../assets/slidehome/27.webp";






function Home() {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showPlayButtonOpacity, setShowPlayButtonOpacity] = useState(false);

  useEffect(() => {
    // Check if the loading page has been shown in this session
    const loadingPageShown = sessionStorage.getItem('loadingPageShown');

    if (loadingPageShown === 'true') {
      // If it has been shown, hide the loading overlay
      setShowLoadingOverlay(false);
      setIsLoading(true);
      document.body.removeAttribute('style');
    }else{
      setShowLoadingOverlay(true);
      setIsLoading(false);
      document.body.style.overflow = 'hidden';
    }
  }, []);


  const handleLinkClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const handleChange = (visible) => {
    const videoElement = videoRef.current;
  
    setIsVisible(visible);
    if (videoElement) {
      if (visible) {
        videoElement.play().catch(error => {
          console.error('Failed to play the video:', error);
        });
      } else {
        videoElement.pause();
      }
    }
  };

  useEffect(() => {

    setTimeout(() => {
      setShowPlayButtonOpacity(true);
    }, 1000);

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('loadeddata', () => {
        // Video is fully loaded
        // setIsLoading(false);
      });

      return () => {
        videoElement.removeEventListener('loadeddata', () => {});
      };
    } else {
      setShowLoadingOverlay(true);
    }
    
  }, []);

  const handlePlayClick = () => {
    const videoElement = videoRef.current;
    document.body.removeAttribute('style');
    if (videoElement) {
        setIsLoading(true);
      videoElement.play().catch(error => {
        console.error('Failed to play the video:', error);
      });
      setShowPlayButton(false);
      setTimeout(() => {
        setShowLoadingOverlay(false);
        window.scrollTo(0, 0);
      }, 100);
    }
    sessionStorage.setItem('loadingPageShown', 'true');
  };



  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     // handleChange();
  //   };

  //   // Attach the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  
  return (
    <>  

{/* loading page start */}
  <div className={`loading-overlay ${showLoadingOverlay ? '' : 'hidden'}`}>
    <div className="loading-spinner mb-5"></div>
    <img src={Logo} alt="logo" className="img-logo-loading mb-5" />
        <button onClick={handlePlayClick} className={`btn-default text-dark loading-button position-absolute py-2 px-4 rounded-pill mb-5 ${showPlayButtonOpacity ? 'opacity-custom-08' : 'opacity-custom-0'}`}     
        style={{
          transition: 'opacity 2s ease-in', 
        }}>
          ENTRA
        </button>
  </div>

{/* loading page end */}

    <Navbar/>
        <div id="home" className="bg-marmo">
        <Helmet>
            <title>Pietro Rinaldi Parrucchiere: Home Page</title>
        </Helmet>

{/* video header start */}
<VisibilitySensor onChange={handleChange} partialVisibility>
          <div className={`video-container ${isLoading ? '' : 'hidden'}`}>
            <video
              className="custom-margin-top"
              playsInline
              width="100%"
              ref={videoRef}
              id="myVideo"
              controls
            >
              <source src={homeVideo} type="video/webm" />
              <source src={homeVideo1} type="video/mp4" />
              Your browser doesn't support this video.
            </video>
          </div>
</VisibilitySensor>
{/* video header end */}
{/* sezione presentazione start*/}
{/* mobile */}
        <div className="container-fluid bg-dark bg-opacity-75 d-block d-md-none">
        <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>

          <div className="row d-flex justify-content-center align-items-end">
            <div className="col-12 py-5">
              <p className="text-animate-gold display-2 fw-bold text-center">Ci presentiamo!</p>
            </div>
            <div className="col-md-6 col-6 text-light mb-4">
              <span className="fw-bold fs-2 text-uppercase text-default">Pietro</span><br/><br/>
              Sono Pietro, il proprietario del salone, e sono stilista nel mondo dello spettacolo e della moda. Da oltre 25 anni creatore di varie acconciature e tagli moda. La mia missione è rendere unica e speciale ogni donna!
              </div>
            <div className="col-md-6 col-6">
              <img src={pietro} className="img-fluid mb-0 pb-0" alt=""/>
            </div>
          </div>
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
        </div>

        <div className="container-fluid bg-dark bg-opacity-75 pt-4 d-block d-md-none">
          <div className="row d-flex justify-content-center align-items-end">
            <div className="col-md-6 col-6">
              <img src={iris} className="img-fluid mb-0 pb-0" alt="" style={{}}/>
            </div>
            <div className="col-md-6 col-6 text-light mb-4">
              <span className="fw-bold fs-2 text-uppercase text-default">Iris</span><br/><br/>
              Stilista con esperienza nei migliori saloni di Verona e con un bagaglio tecnico sul mondo del colore di oltre 15 anni di esperienza! La mia missione è valorizzare la donna e dare una personalità al colore, risaltando il viso e il carattere della persona!
            </div>
          </div>
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
        </div>

        <div className="container-fluid bg-dark bg-opacity-75 pt-4 d-block d-md-none">
          <div className="row d-flex justify-content-center align-items-end">
            <div className="col-md-6 col-6 text-light mb-4">
              <span className="fw-bold fs-2 text-uppercase text-default">Walter</span><br/><br/>
              Nome d'arte JB. Faccio questo mestiere da 7 anni, ho iniziato alla tenera età di 16 anni. Per me non è solo tagliare i capelli, ma è esprimere la mia arte nei minimi dettagli, precisione e cura della persona!
            </div>
            <div className="col-md-6 col-6">
              <img src={walter} className="img-fluid mb-0 pb-0" alt="" style={{}}/>
            </div>
          </div>
        </div>
{/* desktop */}
      <div className="container-fluid bg-dark bg-opacity-75 d-none d-md-block">
        <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
          <div className="row d-flex justify-content-center align-items-end">
            <div className="col-12 py-5">
              <p className="text-animate-gold display-2 fw-bold text-center">Ci presentiamo!</p>
            </div>
            <div className="col-3 text-center">
              <div className="col-12">
                <img src={pietro} className="img-fluid mb-0 pb-0" alt=""/>
              </div>
              <div className="col-12 mt-4 text-light mb-4">
                <span className="fw-bold fs-2 text-uppercase text-default">Pietro</span><br/><br/>
                Sono Pietro, il proprietario del salone, e sono stilista nel mondo dello spettacolo e della moda. Da oltre 25 anni creatore di varie acconciature e tagli moda. La mia missione è rendere unica e speciale ogni donna!
                </div>
            </div>
            <div className="col-3 text-center">
              <div className="col-12">
                <img src={iris} className="img-fluid mb-0 pb-0" alt="" style={{}}/>
              </div>
              <div className="col-12 mt-4 text-light mb-4">
                <span className="fw-bold fs-2 text-uppercase text-default">Iris</span><br/><br/>
                Stilista con esperienza nei migliori saloni di Verona e con un bagaglio tecnico sul mondo del colore di oltre 15 anni di esperienza! La mia missione è valorizzare la donna e dare una personalità al colore, risaltando il viso e il carattere della persona!
              </div>
            </div>
            <div className="col-3 text-center">
              <div className="col-12">
                <img src={walter} className="img-fluid mb-0 pb-0" alt="" style={{}}/>
              </div>
              <div className="col-12 mt-4 text-light mb-4">
                <span className="fw-bold fs-2 text-uppercase text-default">Walter</span><br/><br/>
                Nome d'arte JB. Faccio questo mestiere da 7 anni, ho iniziato alla tenera età di 16 anni. Per me non è solo tagliare i capelli, ma è esprimere la mia arte nei minimi dettagli, precisione e cura della persona!
              </div>
            </div>
          </div>
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
      </div>
{/* sezione presentazione end*/}

        <div className="container-fluid bg-dark bg-opacity-75">
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
        </div>
          <img src={teamImg} alt='team' className="img-fluid d-block d-md-none"/>
        <div className="container-fluid bg-dark bg-opacity-75 img-fluid d-block d-md-none">
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
        </div>
{/* sez. servizi start*/}
        <div className="container-fluid bg-dark bg-opacity-75 d-block d-md-none">
          <div className="row d-flex justify-content-center align-items-end">
            <div className="col-12 pt-5 pb-3">
              <p className="text-animate-gold display-4 fw-bold text-center">I nostri servizi</p>
            </div>
            <div className="col-12 mb-4">
              <p className="text-center text-default fw-bold fs-3">Listino DONNA</p>
              <ul>
                <li className="text-light">
                  Taglio
                </li>
                <li className="text-light">
                Colore
                </li>
                <li className="text-light">
                Balayage
                </li>
                <li className="text-light">
                Meches
                </li>
                <li className="text-light">
                Shatush
                </li>
                <li className="text-light">
                Colpi di Sole
                </li>
                <li className="text-light">
                Permanente
                </li>
                <li className="text-light">
                Stiraggio
                </li>
                <li className="text-light">
                Acconciature
                </li>
                <li className="text-light">
                Acconciature Sposa
                </li>
                <li className="text-light">
                Consulenza Stilistica
                </li>
                <li className="text-light">
                Consulenza Tricologica
                </li>
                <li className="text-light">
                Trattamenti Curativi
                </li>
              </ul>
            </div>
            <div className="col-12 my-4">
              <p className="text-center text-default fw-bold fs-3">Listino UOMO</p>
              <ul>
                <li className="text-light">
                  Taglio
                </li>
                <li className="text-light">
                  Barba
                </li>
                <li className="text-light">
                  Hair tattoo (Disegni)
                </li>
              </ul>
            </div>
          </div>
        </div>
{/* desktop */}
        <div className="container-fluid bg-dark bg-opacity-75 d-none d-md-block">
          <div className="row d-flex justify-content-center align-items-top pb-5">
            <div className="col-12 pt-5 pb-3">
              <p className="text-animate-gold display-4 fw-bold text-center">I nostri servizi</p>
            </div>
            <div className="col-7">
              <img src={teamImg} alt='team' className="img-fluid rounded-25"/>
            </div>
            <div className="col-2 text-center">
                  <p className="text-center text-default fw-bold fs-3">Listino DONNA</p>
                  <ul className="list-unstyled">
                    <li className="text-light">
                      Taglio
                    </li>
                    <li className="text-light">
                    Colore
                    </li>
                    <li className="text-light">
                    Balayage
                    </li>
                    <li className="text-light">
                    Meches
                    </li>
                    <li className="text-light">
                    Shatush
                    </li>
                    <li className="text-light">
                    Colpi di Sole
                    </li>
                    <li className="text-light">
                    Permanente
                    </li>
                    <li className="text-light">
                    Stiraggio
                    </li>
                    <li className="text-light">
                    Acconciature
                    </li>
                    <li className="text-light">
                    Acconciature Sposa
                    </li>
                    <li className="text-light">
                    Consulenza Stilistica
                    </li>
                    <li className="text-light">
                    Consulenza Tricologica
                    </li>
                    <li className="text-light">
                    Trattamenti Curativi
                    </li>
                  </ul>
                  <p className="text-center text-default fw-bold fs-3">Listino UOMO</p>
                  <ul className="list-unstyled">
                    <li className="text-light">
                      Taglio
                    </li>
                    <li className="text-light">
                      Barba
                    </li>
                    <li className="text-light">
                      Hair tattoo (Disegni)
                    </li>
                  </ul>
            </div>
          </div>
        </div>
{/* sez. servizi end*/}
{/* prenota subito start */}
        <div className="container-fluid bg-dark bg-opacity-75">
        <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 py-5 text-center">
              <p className="text-animate-gold fs-1 fw-bold text-center">Prenota Subito</p>
              <p className="text-light fs-5 text-center mb-4">
                Non vogliamo essere dei Robot...
                <br/>
                preferiamo ancora il contatto umano!
              </p>
              <a href="tel:0457501600" target="_blank" className="btn-default text-decoration-none py-2 px-4 rounded fs-5">Chiamaci</a>
            </div>
          </div>
        </div>
{/* prenota subito end */}

{/* slide show start*/}
        <div className="container-fluid bg-dark bg-opacity-75">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-6">
            <Swiper
              slidesPerView={1}
              spaceBetween={-150}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              autoplay={{
                delay: 1200,
                disableOnInteraction: false,
              }}
              loop={false}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={false}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: -150,
                },

              }}
              modules={[Autoplay, EffectCoverflow, Pagination]}
              className="mySwiper"
              >
              <SwiperSlide>
                <img src={img1} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img2} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img3} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img4} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img5} className="img-fluid" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img6} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img7} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img8} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img9} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img10} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img11} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img12} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img13} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img14} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img15} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img16} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img17} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img18} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img19} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img20} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img21} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img22} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img23} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img24} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img25} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img26} className="img-fluid" />
              </SwiperSlide>              <SwiperSlide>
                <img src={img27} className="img-fluid" />
              </SwiperSlide>
            </Swiper>
            </div>
            <div className="text-center mb-5 mt-4">
              <Link to="/gallery"><button onClick={handleLinkClick} className="btn-default px-4 py-1 rounded fs-5">Gallery</button></Link>
            </div>
          </div>
        </div>
{/* slide show end*/}

{/* maps start */}
        <div className="container-fluid bg-dark bg-opacity-75">
        <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
          <div className="row d-flex justify-content-center align-items-center py-5">
            <div className="col-12 text-center">
              <h1 className="text-animate-gold mb-4 fs-1 fw-bold text-center">
                Parrucchiere e Barbiere
                <br/>
                a Verona e Negrar
                </h1>
              <p className="text-default fs-5 text-center mb-4">
                Dove siamo  
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center">
            <Wrapper apiKey="AIzaSyCriIEj2RMyNv9A7kjL3Z_7pDTO8YNfNd8">
                <MapOP />
            </Wrapper>
            </div>
            <div className="col-md-6 d-none d-md-block text-center">
              <div className="col-12 mb-5">
                <ul className="list-unstyled">
                  <li className="text-light mb-3 d-flex justify-content-center col-7">
                  <p className="text-animate-gold fs-1 fw-bold">ORARI</p>
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold">Lunedì:</div> 
                  <ul className="list-unstyled">
                    <li>
                      Chiuso
                    </li>
                  </ul>
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                    <div className="me-3 col-3 ms-5 fw-bold">Martedì:</div> 
                    <ul className="list-unstyled">
                      <li>
                        08:30 - 12:30
                      </li>
                      <li>
                        15:00 - 19:30
                      </li>
                    </ul> 
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                    <div className="me-3 col-3 ms-5 fw-bold">Mercoledì:</div> 
                    <ul className="list-unstyled">
                      <li>
                        08:30 - 12:30
                      </li>
                      <li>
                        15:00 - 19:30
                      </li>
                    </ul> 
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                    <div className="me-3 col-3 ms-5 fw-bold">Giovedì:</div> 
                    <ul className="list-unstyled">
                      <li>
                        08:30 - 12:30
                      </li>
                      <li>
                        15:00 - 19:30
                      </li>
                    </ul> 
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                    <div className="me-3 col-3 ms-5 fw-bold">Venerdì:</div> 
                    <ul className="list-unstyled">
                      <li>
                        08:30 - 19:30
                      </li>
                    </ul> 
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                    <div className="me-3 col-3 ms-5 fw-bold">Sabato:</div> 
                    <ul className="list-unstyled">
                      <li>
                        08:30 - 18:00
                      </li>
                    </ul> 
                  </li>
                  <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold" id="contatti">Domenica:</div> 
                  <ul className="list-unstyled">
                    <li>
                      Chiuso
                    </li>
                  </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="container-fluid d-none d-md-block">
            <div className="row bg-animate-gold my-5" style={{"height":"0.2rem"}}></div>
              <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-12 text-center">
                    <p className="text-animate-gold fs-1 fw-bold text-center">CONTATTI</p>
                  </div>
                  <div className="col-2 text-center">
                  <a href="https://maps.app.goo.gl/ZPqBnXm5e2UmUret5" target="_blank" className="text-decoration-none text-light"><FaMapMarkerAlt className="display-3 text-default mb-4"/>
                  </a><br/>
                  <a href="https://maps.app.goo.gl/ZPqBnXm5e2UmUret5" target="_blank" className="text-decoration-none text-light">&nbsp;Via Del Combattente, 3/B, 37024 Negrar VR</a>
                  </div>

                  <div className="col-2 text-center">
                  <a href="https://www.facebook.com/profile.php?id=100064064824081" target="_blank" className="text-decoration-none text-light"><BsFacebook className="display-3 text-default mb-4"/>
                  </a><br/>
                  <a href="https://www.facebook.com/profile.php?id=100064064824081" target="_blank" className="text-decoration-none text-light">&nbsp;@pietrorinaldiparrucchiere</a>
                  </div>

                  <div className="col-2 text-center">
                  <a href="https://www.instagram.com/pietro.rinaldi.hairstyle/" target="_blank" className="text-decoration-none text-light"><BsInstagram className="display-3 text-default mb-4"/>
                  </a><br/>
                  <a href="https://www.instagram.com/pietro.rinaldi.hairstyle/" target="_blank" className="text-decoration-none text-light">&nbsp;@pietro.rinaldi.hairstyle</a>
                  </div>
                  {/* <div className="col-2 text-center">
                  <a href="https://wa.me/3429396971" target="_blank" className="text-decoration-none text-light"><BsWhatsapp className="display-3 text-default mb-4"/>
                  </a><br/>
                  <a href="https://wa.me/3429396971" target="_blank" className="text-decoration-none text-light">&nbsp;3429396971</a>
                  </div> */}

                  <div className="col-2 text-center">
                  <a href="tel:0457501600" target="_blank" className="text-decoration-none text-light"><BsTelephoneFill className="display-3 text-default mb-4"/>
                  </a><br/>
                  <a href="tel:0457501600" target="_blank" className="text-decoration-none text-light">&nbsp;0457501600</a>
                  </div>
              </div>
            </div>
          </div>
        </div>
{/* maps end */}

{/* orari-contatti start */}
        <div className="container-fluid bg-dark bg-opacity-75 d-block d-md-none">
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
          <div className="row d-flex justify-content-center align-items-end py-5">
            <div className="col-12 mb-3">
              <p className="text-animate-gold fs-1 fw-bold text-center">ORARI</p>
            </div>
            <div className="col-12 mb-5">
              <ul className="list-unstyled">
                <li className="text-light mb-3 d-flex justify-content-start">
                <div className="me-3 col-3 ms-5 fw-bold">Lunedì:</div> 
                <ul className="list-unstyled">
                  <li>
                    Chiuso
                  </li>
                </ul>
                </li>
                <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold">Martedì:</div> 
                  <ul className="list-unstyled">
                    <li>
                      08:30 - 12:30
                    </li>
                    <li>
                      15:00 - 19:30
                    </li>
                  </ul> 
                </li>
                <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold">Mercoledì:</div> 
                  <ul className="list-unstyled">
                    <li>
                      08:30 - 12:30
                    </li>
                    <li>
                      15:00 - 19:30
                    </li>
                  </ul> 
                </li>
                <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold">Giovedì:</div> 
                  <ul className="list-unstyled">
                    <li>
                      08:30 - 12:30
                    </li>
                    <li>
                      15:00 - 19:30
                    </li>
                  </ul> 
                </li>
                <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold">Venerdì:</div> 
                  <ul className="list-unstyled">
                    <li>
                      08:30 - 19:30
                    </li>
                  </ul> 
                </li>
                <li className="text-light mb-3 d-flex justify-content-start">
                  <div className="me-3 col-3 ms-5 fw-bold">Sabato:</div> 
                  <ul className="list-unstyled">
                    <li>
                      08:30 - 18:00
                    </li>
                  </ul> 
                </li>
                <li className="text-light mb-3 d-flex justify-content-start">
                <div className="me-3 col-3 ms-5 fw-bold" id="contatti">Domenica:</div> 
                <ul className="list-unstyled">
                  <li>
                    Chiuso
                  </li>
                </ul>
                </li>
              </ul>
            </div>
            <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>

            <div className="col-12 mt-5 mb-3">
              <p className="text-animate-gold fs-1 fw-bold text-center">CONTATTI</p>
            </div>
            <div className="col-10">
              <ul className="list-unstyled">
                <li className="text-light d-flex align-items-center mb-4">
                  <div className="me-3">
                  <a href="https://maps.app.goo.gl/ZPqBnXm5e2UmUret5" target="_blank" className="text-decoration-none text-light"><FaMapMarkerAlt className="display-3 text-default"/>
                  </a>
                  </div>
                  <a href="https://maps.app.goo.gl/ZPqBnXm5e2UmUret5" target="_blank" className="text-decoration-none text-light">&nbsp;Via Del Combattente, 3/B, 37024 Negrar VR</a>
                </li>
                <li className="text-light d-flex align-items-center mb-4">
                  <div className="me-3">
                    <a href="https://www.facebook.com/profile.php?id=100064064824081" target="_blank" className="text-decoration-none text-light"><BsFacebook className="display-3 text-default"/>
                  </a>
                  </div>
                  <div>
                    <a href="https://www.facebook.com/profile.php?id=100064064824081" target="_blank" className="text-decoration-none text-light">&nbsp;@pietrorinaldiparrucchiere</a>
                  </div>
                </li>
                <li className="text-light d-flex align-items-center mb-4">
                  <div className="me-3">
                    <a href="https://www.instagram.com/pietro.rinaldi.hairstyle/" target="_blank" className="text-decoration-none text-light"><BsInstagram className="display-3 text-default"/>
                  </a>
                  </div>
                  <div>
                    <a href="https://www.instagram.com/pietro.rinaldi.hairstyle/" target="_blank" className="text-decoration-none text-light">&nbsp;@pietro.rinaldi.hairstyle</a>
                  </div>
                </li>
                {/* <li className="text-light d-flex align-items-center mb-4">
                  <div className="me-3">
                    <a href="https://wa.me/3429396971" target="_blank" className="text-decoration-none text-light"><BsWhatsapp className="display-3 text-default"/>
                  </a>
                  </div>
                  <div>
                    <a href="https://wa.me/3429396971" target="_blank" className="text-decoration-none text-light">&nbsp;3429396971</a>
                  </div>
                </li> */}
                <li className="text-light d-flex align-items-center mb-4">
                  <div className="me-3">
                    <a href="tel:0457501600" target="_blank" className="text-decoration-none text-light"><BsTelephoneFill className="display-3 text-default"/>
                  </a>
                  </div>
                  <div>
                    <a href="tel:0457501600" target="_blank" className="text-decoration-none text-light">&nbsp;0457501600</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row bg-animate-gold" style={{"height":"0.2rem"}}></div>
        </div>
{/* orari-contatti end */}

        <CallToAction/>
        <Footer/>
        </div>
    </>
  )
}

export default Home
